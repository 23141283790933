import React, { useRef } from 'react';

import Container from '../components/Container';
import Hero from '../components/Hero';
import ThemeLink from '../components/ThemeLink';
import Layout from '../components/Layout/Layout';
import Quote from '../components/Quote';

import * as styles from './about.module.css';
const AboutPage = (props) => {
  let historyRef = useRef();
  let valuesRef = useRef();
  let sustainabilityRef = useRef();

  const handleScroll = (elementReference) => {
    if (elementReference) {
      window.scrollTo({
        behavior: 'smooth',
        top: elementReference.current.offsetTop - 280,
      });
    }
  };

  return (
    <Layout disablePaddingBottom>
      <div className={styles.root}>
        <Quote
        bgColor={'var(--standard-light-grey)'}
        title={'Goal'}
        quote={
          '“I created this small blog type page to post summaries of various books, Youtube channels, Podcasts and more. Adding and improving the content weekly in English and Latvian.”'
        }
      />
      </div>
      <div className={styles.root}>
        <Quote
        bgColor={'var(--standard-light-grey)'}
        title={'Mērķis'}
        quote={
          '“Es izveidoju šo mazo emuāra tipa lapu, lai ievietotu dažādu grāmatu kopsavilkumus, Youtube kanālu kopsavilkumu un daudz ko citu. Es veicu satura pievienošanu un uzlabošanu katru nedēļu Angļu un Latviešu valodā.”'
        }
      />
      </div>
    </Layout>
  );
};

export default AboutPage;
